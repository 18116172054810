import React, { FC, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../../context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { E164Number, isValidPhoneNumber } from "libphonenumber-js";
import "react-phone-number-input/style.css";

import pomegranate from "../../../assets/pomegranate.svg";
import { signupUser } from "../../../helpers/Api";
import SubmitButton from "../../../helpers/SubmitButton";
import first_step from "../../../assets/first_step.svg";
// import { Helmet } from "react-helmet";

interface SignupFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: E164Number | null;
  checkbox: boolean;
}

const Signup: FC = () => {
  const { dispatch } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [confirmPasswordShown, setConfirmPasswordShown] =
    useState<boolean>(false);
  const handleSignup = async (values: SignupFormValues) => {
    try {
      if (!values.phoneNumber || !isValidPhoneNumber(values.phoneNumber)) {
        Swal.fire("Oops!", "Enter a valid phone number", "error");
        return;
      }

      const response = await signupUser(
        values.phoneNumber,
        values.email,
        values.password,
        values.checkbox
      );
      if (response && response.data && response.data.token) {
        dispatch({ type: "SET_SIGNUP_STEP", payload: 1 });
        navigate("/signup/phone-verification", {
          state: { phoneNumber: values.phoneNumber },
        });
      } else if (response && response.success === false) {
        Swal.fire("Oops!", response.message, "error");
      } else if (response.message) {
        Swal.fire("Oops!", response.message, "error");
      } else {
        Swal.fire("Oops!", "An unexpected error has occurred", "error");
      }
    } catch (err: any) {
      let errorMessage = "Something went wrong. Please try again.";

      if (err.response && err.response.data) {
        errorMessage = err.response.data.message || errorMessage;
      } else if (err.message) {
        errorMessage = err.message;
      }
      setError(errorMessage);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight={"100vh"} className="login__box">
        <Grid
          container
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />
          <div className="login__brand_text">
            <p>Create an account to get started!</p>
            <p style={{ fontSize: 35, fontWeight: 700 }}>Sign up</p>

            <p>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  color: "#04BF7B",
                  fontWeight: "inherit",
                }}
              >
                <b>Log in</b>
              </Link>
            </p>
          </div>

          <img
            src={pomegranate}
            className="login_pomegranate"
            alt="GitHub Logo"
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems={{ md: "stretch", xs: "center" }}
          justifyContent="center"
          className="login__card"
        >
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              phoneNumber: null,
              checkbox: false,
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              password: Yup.string()
                .min(8, "Password must be at least 8 characters")
                .required("Required"),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Required"),
              phoneNumber: Yup.string()
                .test(
                  "is-valid-phone",
                  "Please enter a valid phone number",
                  (value) => {
                    if (!value) return false;
                    try {
                      return isValidPhoneNumber(value);
                    } catch (error) {
                      return false;
                    }
                  }
                )
                .required("Phone number is required"),
              checkbox: Yup.boolean()
                .oneOf([true], "You must accept the terms and conditions")
                .required("Required"),
            })}
            onSubmit={(values: SignupFormValues) => {
              handleSignup(values);
            }}
          >
            {({ setFieldValue, values, touched, errors, isSubmitting }) => (
              <Form noValidate role="form">
                <div>
                  <div className="loginSmallDevices__brand_text">
                    <p style={{ fontSize: 35, fontWeight: 700 }}>Sign up</p>
                  </div>
                  <p>Your account details</p>
                  <Box mb={2}>
                    <div className="input-group">
                      <Field
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        className={`form-control customField_user mb-3 ${
                          touched.email
                            ? errors.email
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        autoComplete="new-email"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box mb={2}>
                    <div className="input-group">
                      <Field
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={`form-control customField mb-3 ${
                          touched.password
                            ? errors.password
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        autoComplete="new-password"
                      />
                      <div className="input-group-text mb-3">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setPasswordShown(passwordShown ? false : true)
                          }
                        >
                          {passwordShown === false && <IoMdEyeOff />}
                          {passwordShown === true && <IoMdEye />}
                        </i>
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box mb={2}>
                    <div className="input-group">
                      <Field
                        type={confirmPasswordShown ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className={`form-control customField mb-3 ${
                          touched.confirmPassword
                            ? errors.confirmPassword
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        autoComplete="new-password"
                      />
                      <div className="input-group-text mb-3">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setConfirmPasswordShown(
                              confirmPasswordShown ? false : true
                            )
                          }
                        >
                          {confirmPasswordShown === false && <IoMdEyeOff />}
                          {confirmPasswordShown === true && <IoMdEye />}
                        </i>
                      </div>
                    </div>

                    <ErrorMessage
                      name="confirmPassword"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box mb={2}>
                    <Field
                      as={PhoneInput}
                      placeholder="Phone Number"
                      defaultCountry="US"
                      value={values.phoneNumber ?? undefined}
                      className={`form-control customField_user mb-4 ${
                        values.phoneNumber
                          ? errors.phoneNumber
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      autoComplete="new-phoneNumber"
                      onChange={(value: E164Number) => {
                        setFieldValue("phoneNumber", value);
                      }}
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box sx={{ textAlign: "start", marginTop: "15px" }}>
                    <p>Message and data rates may apply.</p>
                  </Box>
                  <div style={{ display: "flex", alignItems: "start" }}>
                    <FormControlLabel
                      className={`mb-3`}
                      control={
                        <Field
                          type="checkbox"
                          name="checkbox"
                          as={Checkbox}
                          onChange={() =>
                            setFieldValue("checkbox", !values.checkbox)
                          }
                        />
                      }
                      label={
                        <span>
                          I agree to PomJuice's{" "}
                          <Link
                            to="/terms-of-service"
                            style={{
                              textDecoration: "none",
                              color: "#04BF7B",
                              fontWeight: "inherit",
                            }}
                          >
                            Terms & Conditions{" "}
                          </Link>
                          and
                          <Link
                            to="/privacy-policy"
                            style={{
                              textDecoration: "none",
                              color: "#04BF7B",
                              fontWeight: "inherit",
                            }}
                          >
                            {" "}
                            Privacy Policy.
                          </Link>
                        </span>
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="checkbox"
                    component="p"
                    className="requiredField"
                  />
                  {error && <p className="text-danger">{error}</p>}
                  <div className="d-flex flex-column flex-md-row align-items-center mt-3 flex-wrap justify-content-center justify-content-md-between">
                    <img
                      src={first_step}
                      alt="First Step"
                      className="img-fluid"
                      style={{
                        marginTop: "30px",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <button
                      className="button__primary color__active"
                      type="submit"
                      style={{ marginTop: "30px", width: "25%" }}
                    >
                      {!isSubmitting ? (
                        "Next"
                      ) : (
                        <CircularProgress
                          sx={{ color: "white", marginTop: "5px" }}
                          size={26}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <div className=" loginSmallDevices__brand_text">
            <p style={{ fontWeight: 400, marginTop: "25px" }}>
              Already have an account?{" "}
              <b>
                <Link
                  to="/login"
                  style={{
                    color: "#04BF7B",
                    fontWeight: "inherit",
                  }}
                >
                  Log in
                </Link>
              </b>
            </p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Signup;
