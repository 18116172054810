import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/private/Dashboard";
import SharedStartUp from "../pages/public/ShareStartUp";
import StartupProfile from "../pages/private/StartupProfile/StarProject/index";
import TellUs from "../pages/private/StartupProfile/TellUs/index";
import BuildProfile from "../pages/private/StartupProfile/BuildProfile/index";
import ProfilePage from "../pages/private/Profile/index";
import Settings from "../pages/private/Settings";
import Login from "../pages/public/Login/index";
import Error from "../pages/public/Error/index";
import { AppContext } from "../context";
import Signup from "../pages/public/SignupFlow/Signup";
import VerificationSuccessful from "../pages/public/SignupFlow/VerificationSuccessful";
import LetsGetToKnowYou from "../pages/public/SignupFlow/LetsGetToKnowYou";
import Welcome from "../pages/public/SignupFlow/Welcome";
import PhoneNumberVerification from "../pages/public/SignupFlow/PhoneNumberVerification";
import Terms from "../pages/public/SignupFlow/Terms";
import PrivacyPolicy from "../pages/public/SignupFlow/PrivacyPolicy";
import MainLayout from "../components/MainLayout";
import VisitorsDetailActivity from "../pages/private/Dashboard/VistorsDetailActivity/VistorsDetailActivity";
import Fundraising from "../pages/private/Fundraising/fundraising";

const ProtectedRoute = ({
  authed,
  children,
  redirectTo,
}: {
  authed: boolean;
  children: React.ReactNode;
  redirectTo: string;
}) => {
  return authed ? <> {children} </> : <Navigate to={redirectTo} />;
};

const PublicRoute = ({
  authed,
  children,
  redirectTo,
}: {
  authed: boolean;
  children: React.ReactNode;
  redirectTo: string;
}) => {
  return !authed ? <> {children} </> : <Navigate to={redirectTo} />;
};

const SignupRoute = ({
  currentStep,
  requiredStep,
  children,
  redirectTo,
}: {
  currentStep: number;
  requiredStep: number;
  children: React.ReactNode;
  redirectTo: string;
}) => {
  return currentStep >= requiredStep ? (
    <> {children} </>
  ) : (
    <Navigate to={redirectTo} />
  );
};

function Users() {
  const { state } = useContext(AppContext);
  const authed = state?.authed;
  const signupStep = state?.signupStep || 0;

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute authed={authed} redirectTo="/">
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute authed={authed} redirectTo="/">
            <Signup />
          </PublicRoute>
        }
      />

      <Route
        path="/signup/phone-verification"
        element={
          <PublicRoute authed={authed} redirectTo="/">
            <PhoneNumberVerification />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/verification"
        element={
          <SignupRoute
            currentStep={signupStep}
            requiredStep={2}
            redirectTo="/signup/phone-verification"
          >
            <VerificationSuccessful />
          </SignupRoute>
        }
      />
      <Route
        path="/signup/lets-get-to-know-you"
        element={
          <SignupRoute
            currentStep={signupStep}
            requiredStep={3}
            redirectTo="/signup/phone-verification"
          >
            <LetsGetToKnowYou />
          </SignupRoute>
        }
      />
      <Route
        path="/signup/welcome"
        element={
          <SignupRoute
            currentStep={signupStep}
            requiredStep={4}
            redirectTo="/signup/lets-get-to-know-you"
          >
            <Welcome />
          </SignupRoute>
        }
      />
      <Route path="/terms-of-service" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/share/:id/:name" element={<SharedStartUp />} />
      <Route
        path="/private/share/:id/:email/:token"
        element={<SharedStartUp />}
      />
      <Route
        path="/"
        element={
          <ProtectedRoute authed={authed} redirectTo="/login">
            <MainLayout>
              <BuildProfile />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/startup-profile/UserProfile"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <BuildProfile />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/startup-profile"
        element={
          <ProtectedRoute authed={authed} redirectTo="/login">
            <MainLayout>
              <StartupProfile />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/startup-profile/tell-us"
        element={
          <ProtectedRoute authed={authed} redirectTo="/login">
            <MainLayout>
              <TellUs />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/startup-profile/profile"
        element={
          <ProtectedRoute authed={authed} redirectTo="/login">
            <MainLayout>
              <BuildProfile />
            </MainLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/profile"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <ProfilePage />
            </MainLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/visitors"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <VisitorsDetailActivity />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <Settings />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/fundraising"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <Fundraising />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/fundraising/my-list"
        element={
          <ProtectedRoute authed={state.authed} redirectTo="/login">
            <MainLayout>
              <Fundraising view={"my-list"} />
            </MainLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute authed={authed} redirectTo="/login">
            <Error />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default Users;
