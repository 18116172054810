import { useContext, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ProfileCreation, updateSocialMediaLinks } from "../../../helpers/Api";
// Image assets
import PumpJuiceLogo from "../../../assets/pompjuice_logo.svg";
import pomegranate from "../../../assets/pomegranate.svg";
import LinkedinButton from "../../../assets/linkedin_contrast.svg";
import Fourth_step from "../../../assets/Fourth_step.svg";
import SubmitButton from "../../../helpers/SubmitButton";
import { AppContext } from "../../../context";

interface LetGetToKnowYouFormValues {
  firstName: string;
  lastName: string;
  description?: string;
  linkedin: string;
  twitter?: string;
  calendar?: string;
}

const LetGetToKnowYou = (): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleProfileSubmission = async (values: LetGetToKnowYouFormValues) => {
    try {
      if (values.firstName && values.lastName && values.linkedin) {
        const response = await ProfileCreation(
          values.firstName,
          values.lastName,
          values.description || ""
        );
        const resSocial = await updateSocialMediaLinks(
          values.linkedin,
          values.twitter,
          values.calendar
        );
        if (response.status === 201 && resSocial.status === 200) {
          dispatch({ type: "SET_SIGNUP_STEP", payload: 4 });
          navigate("/signup/welcome");
        } else {
          Swal.fire({
            title: "Oops!",
            text: "An error has occurred",
            icon: "error",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "swal2-confirm btn btn-primary",
            },
          });
        }
      }
    } catch (err: any) {
      //console.error("Profile Creation error:", err);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again.";
      setError(errorMessage);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container minHeight="100vh" className="login__box">
        <Grid
          item
          xs={12}
          md={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="login__brand"
        >
          <div className="login__semiCircle">
            <p></p>
          </div>
          <img src={PumpJuiceLogo} className="login_img" alt="PumpJuiceLogo" />
          <div className="login__brand_text">
            <p>Enter your personal information!</p>
            <p style={{ fontSize: 35, fontWeight: 700 }}>
              {" "}
              Let’s get to know you!
            </p>

            <p>
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontWeight: "inherit",
                }}
              >
                <b>Log in</b>
              </Link>
            </p>
          </div>
          <img
            src={pomegranate}
            className="login_pomegranate"
            alt="Pomegranate Logo"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="center"
          className="login__card"
        >
          <Box className="signup__form">
            <Typography variant="h4" textAlign="start" mb={2}>
              Your personal information
            </Typography>
            {/* <Box className="signup__button_social" mb={2}>
              <button className="social__button">
                <span
                  style={{ marginRight: "5px" }}
                  className="social__button_text"
                >
                  Connect with
                </span>
                <img
                  src={LinkedinButton}
                  alt="Linkedin Logo"
                  className="social__button_logo"
                />
              </button>
            </Box> */}
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                description: "",
                linkedin: "",
                twitter: "",
                calendar: "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                description: Yup.string(),
                linkedin: Yup.string()
                  .url("Must be a valid URL")
                  .required("Required"),
                twitter: Yup.string().url("Must be a valid URL"),
                calendar: Yup.string().url("Must be a valid URL"),
              })}
              onSubmit={(values: LetGetToKnowYouFormValues) =>
                handleProfileSubmission(values)
              }
            >
              {({ errors, touched, isValid, dirty }) => (
                <Form noValidate role="form">
                  <Box
                    display="flex"
                    mt={2}
                    justifyContent="space-between"
                    width="70%"
                    sx={{ "@media (max-width:900px)": { width: "100%" } }}
                  >
                    <Box width="49%">
                      <Field
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        className={`form-control customField mb-4 ${
                          touched.firstName
                            ? errors.firstName
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        style={{ width: "100%" }}
                        required={true}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="p"
                        className="requiredField"
                      />
                    </Box>
                    <Box width="49%">
                      <Field
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        className={`form-control customField mb-4 ${
                          touched.lastName
                            ? errors.lastName
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        required={true}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="p"
                        className="requiredField"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      "@media (max-width:900px)": { width: "100%" },
                    }}
                  >
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Quick tagline (optional, e.g. Tech Enthusiast)"
                      className="form-control mb-3 mt-2"
                      style={{ width: "100%", height: "123px" }}
                      maxLength={250}
                    />
                    {touched.description && errors.description && (
                      <p className="requiredField">{errors.description}</p>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      "@media (max-width:900px)": { width: "100%" },
                    }}
                  >
                    <Field
                      name="linkedin"
                      type="text"
                      placeholder="LinkedIn (Required)"
                      className={`form-control customField mb-4 ${
                        touched.linkedin
                          ? errors.linkedin
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      required={true}
                    />
                    <ErrorMessage
                      name="linkedin"
                      component="p"
                      className="requiredField_onboarding"
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      "@media (max-width:900px)": { width: "100%" },
                    }}
                  >
                    <Field
                      name="twitter"
                      type="text"
                      placeholder="Twitter / X (Optional)"
                      className={`form-control customField mb-4 ${
                        touched.twitter
                          ? errors.twitter
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      "@media (max-width:900px)": { width: "100%" },
                    }}
                  >
                    <Field
                      name="calendar"
                      type="text"
                      placeholder="Calendar Booking Link (Optional)"
                      className={`form-control customField mb-4 ${
                        touched.calendar
                          ? errors.calendar
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                    />
                  </Box>
                  <div className="d-flex flex-column flex-md-row align-items-center mt-3 flex-wrap justify-content-center justify-content-md-between">
                    <img
                      src={Fourth_step}
                      alt="First Step"
                      className="img-fluid"
                      style={{
                        marginTop: "30px",
                        cursor: "pointer",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <button
                      className="button__primary color__active"
                      type="submit"
                      style={{ marginTop: "30px", width: "25%" }}
                    >
                      Next
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LetGetToKnowYou;
